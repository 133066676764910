<template>
    <v-container>
        <v-card flat>
            <nice-header>Legal documents for {{ $store.name }}</nice-header>
            <v-card-text>
                By placing an order with {{ $store.name }}, you must agree to the terms set out in the below documents
            </v-card-text>
            <v-row class="justify-center align-center">
                <v-col cols="12" md="8">
                    <v-list subheader>
                        <v-subheader>Documents</v-subheader>
                        <v-divider></v-divider>
                        <template v-for="(doc, i) in $store.legalDocuments">
                            <v-list-item :key="i">
                                <v-list-item-title>
                                    {{ doc.description }}
                                </v-list-item-title>
                                <v-list-item-action>
                                    <v-btn icon :href="doc.url">
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="`divider-${i}`"/>
                        </template>
                    </v-list>
                </v-col>
            </v-row>

        </v-card>
    </v-container>

</template>

<script>
    export default {}
</script>
